import React from "react";
import cookie from "react-cookies";

class Voucheritem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: cookie.load("businessName"),
      someKey: "someValue",
    };
  }

  render() {
    return (
      <div
        className="col-sm card mx-2 py-3 px-4 "
        style={{ background: "#f7f8fa" }}
      >
        <h3 className="item">Unicard - {this.state.businessName}</h3>

        <div style={{ marginTop: 10 + "px" }}>
          <h6 className="item">Serial: {this.props.serial}</h6>
          <h6 className="item">Pin: {this.props.pin}</h6>
          <h6 className="item">Date: {this.props.dateCreated}</h6>
        </div>
        <span class="breadcrumb-item active" style={{ fontSize: 12 }}>
          Printed Voucher are responsibility of the customer, protect your pin.
        </span>
        <span
          class=""
          style={{
            fontSize: 15,
            fontWeight: "bold",
            marginTop: 10 + "px",
            textAlign: "right",
          }}
        >
          <span class="breadcrumb-item active" style={{ fontSize: 12 }}>
            Powered by
          </span>{" "}
          TopUpBox
        </span>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default Voucheritem;
