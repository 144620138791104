import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import { WEB_BASE_URL } from "./webbase";

class CreateUser extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      formEmail: "",
      formRole: "",
      formName: "",
    };
    this.onInviteUser = this.onInviteUser.bind(this);

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
  }

  
  onInviteUser(event) {
    event.preventDefault();
    if (
      this.state.formEmail !== "" &&
      this.state.formRole !== "" &&
      this.state.formName !== ""
    ) {
      const id = toast.loading("Please wait...");
      const user = {
        dealer: "demiglobal",
        emailAddress: this.state.formEmail,
        role: this.state.formRole,
        fullname: this.state.formName,
      };
      axios
        .post(WEB_BASE_URL + "/admin/invite-user", user, {
          headers: {
            authorization: this.state.token,
          },
        })
        .then((res) => {
          if (res.data.status === "2000") {
            toast.update(id, {
              render: "Invitation sent successfully",
              type: "success",
              isLoading: false,
            });
          } else {
            console.log(res);
            toast.update(id, {
              render: "Invitation failed",
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: err,
            type: "error",
            isLoading: false,
          });
        });
    } else {
      toast.error("Form is not Complete");
    }
  }

  //set state for formEmail
  onChangeEmail(event) {
    this.setState({
      formEmail: event.target.value,
    });
  }

  //set state for formName
  onChangeName(event) {
    this.setState({
      formName: event.target.value,
    });
  }

  //set state for formRole
  onChangeRole(event) {
    this.setState({
      formRole: event.target.value,
    });
  }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Invite User
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;200
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form
              className="row"
              onSubmit={(e) => {
                this.onInviteUser(e);
              }}
            >
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    onChange={(event) => this.onChangeEmail(event)}
                    value={this.state.formEmail}
                    class="form-control datepicker "
                    id="P1254294827"
                    required
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Full Name</label>
                  <input
                    type="text"
                    onChange={(event) => this.onChangeName(event)}
                    value={this.state.formName}
                    class="form-control datepicker "
                    id="P1254294827"
                    required
                  />
                </div>
              </div>

              <div className="row my-3">
                <div className="col-4">
                  <label className="form-label">Role</label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    value={this.state.formRole}
                    onChange={(event) => this.onChangeRole(event)}
                    required
                  >
                    <option selected=""> Select Role</option>
                    <option value="TOPUPBOX_OPERATOR">TOPUPBOX_OPERATOR</option>
                    <option value="TOPUPBOX_MANAGER">TOPUPBOX_MANAGER</option>
                    <option value="TOPUPBOX_ADMIN">TOPUPBOX_ADMIN</option>
                  </select>
                </div>
                <div className="col-4">
                  <button
                    type="submit"
                    style={{ marginTop: 29 }}
                    className="btn btn-primary d-flex"
                  >
                    <div>
                      <i class="bi bi-cloud-arrow-down-fill"></i>
                    </div>
                    <div className="mx-3">Invite User</div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default CreateUser;
