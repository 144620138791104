import React from "react";
import Modal from "./modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";
class ViewMerchants extends React.Component {
    constructor() {
        super();
        this.state = {
            userEmail: cookie.load("useAdmin"),
            token: cookie.load("tokenAdmin"),
            users: [],
        };
        console.log(this.state.token);
        this.onInviteUser = this.onInviteUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.reInvite = this.reInvite.bind(this);
    }

    onInviteUser(event) {
        event.preventDefault();
        if (this.state.formEmail !== "" && this.state.formRole !== "") {
            toast.success("User Role Edited!!!");
        } else {
            toast.error("User Role Edit failed!!!");
        }
    }

    reInvite(email) {
        console.log(email);
        axios
            .post(
                WEB_BASE_URL + "/admin/re-invite-merchant?emailAddress=" + email,
                {},
                {
                    headers: {
                        authorization: this.state.token,
                        emailAddress: email,
                    },
                }
            )
            .then((res) => {
                console.log(res);
                if (res.data.status === "2000") {
                    toast.success("merchant Reinvited successfully");
                } else {
                    toast.error(res.data.description);
                }

                this.getUsers();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //get all users
    getUsers() {
        console.log(this.state.token);
        axios
            .post(UNIFIED_BASE_URL + "/onboarding/all-merchants", {}, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.status === "2000") {
                    this.setState({
                        users: res.data.data.data,
                    });
                } else {
                    toast.error(res.data.description);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //set state for formRole
    onChangeRole(event) { }

    componentDidMount() {
        this.getUsers();
    }

    render() {
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    All Merchants
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto"></div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">All Users Under You</h5>
                            <form className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                    <i className="bi bi-search"></i>
                                </div>
                                <input
                                    className="form-control ps-5"
                                    type="text"
                                    placeholder="search"
                                />
                            </form>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table align-middle">
                                <thead className="table-secondary">
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>States</th>
                                        <th>Role</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                                    <img
                                                        src="assets/images/dummy.png"
                                                        className="rounded-circle"
                                                        width="44"
                                                        height="44"
                                                        alt=""
                                                    />
                                                    <div className="">
                                                        <p className="mb-0"></p>
                                                        {user.dealerName}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{user?.businessEmail}</td>
                                            <td>{user?.states}</td>
                                            <td>Merchant</td>
                                            <td>
                                                <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                    <a
                                                        href="javascript:void(0);"
                                                        class="text-success mx-2"
                                                        title="ReInvite Dealer"
                                                        data-bs-original-title="Reprint"
                                                        aria-label="Reprint"
                                                        onClick={(e) => this.reInvite(user?.businessEmail)}
                                                    >
                                                        <i class="bi  bi-arrow-repeat"></i>
                                                    </a>
                                                    <a
                                                        href="javascript:;"
                                                        className="text-danger"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Delete"
                                                    >
                                                        <i className="bi bi-trash-fill"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Modal
                                title="Edit User"
                                id="editModal"
                                buttonText="Effect Role Change"
                                onSubmit={this.onInviteUser}
                                onChangeEmail={this.onChangeEmail}
                                onChangeRole={this.onChangeRole}
                                formEmail={"Thomas@gmail.com"}
                                formRole={this.state.formRole}
                            ></Modal>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ViewMerchants;
