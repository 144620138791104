import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { WEB_BASE_URL } from "./webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  ArcElement,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { BlockLoading } from 'react-loadingg';

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
    };
    this.getVouchers = this.getVouchers.bind(this);

    ChartJS.register(
      ArcElement,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend,
      Filler,
    );
  }

  getVouchers = () => {
    const id = toast.loading("Please wait...");
    axios
      .get(WEB_BASE_URL + "/api/v2/u1/pin-manager", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        res.data.data.reverse();
        this.setState({ vouchers: res.data.data });
        toast.dismiss(id);
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      });
  };
  componentDidMount() {
    //  this.getVouchers();
  }

  render() {

    const dates = [];
    const transactions = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(date.toLocaleDateString());

      const transactionCount = Math.floor(Math.random() * 100); // Random transaction count
      transactions.push(transactionCount);
    }

    //const labels = Object.keys(this.state.dataByDate);
    const options = {
      responsive: true,
      plugins: [{
        legend: {
          position: 'top',
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
      },
      {
        beforeDraw: (chart) => {
          if (this.state.isLoading) {
            const canvas = chart.canvas;
            const ctx = canvas.getContext('2d');
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;

            ctx.save();
            ctx.beginPath();
            ctx.translate(centerX, centerY);
            BlockLoading.render({
              size: 50,
              color: '#263B95',
              speed: 1,
              duration: 2000
            }, ctx);
            ctx.restore();
          }
        }
      }
      ],
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      }
    };
    const data = {
      labels: dates,
      datasets: [
        {
          label: 'TopupCliq Transactions - This Week',
          data: transactions,
          backgroundColor: '#263B95',
          borderColor: '#263B95',
          borderWidth: 2,
          fill: true, // Add color to the area underneath the line
        },
      ],
    };

    const pieData = {
      labels: dates,
      datasets: [
        {
          label: 'Dealer Ratio',
          data: transactions,
          backgroundColor: ['#50aadf', '#263B95', "#E0115F", '#7925c6'],
          borderColor: '#ffffff',
          borderWidth: 2,
          fill: true, // Add color to the area underneath the line
        },
      ],
    };

    return (
      <div className="page-content">
        <div className="row">
          <div className="col-md-9">
            <div className="card overflow-hidden radius-10">
              <div className="card-body align-items-center flex justify-content-between" style={{
                minHeight: "30vh",
                maxHeight: "40vh",
                paddingLeft: "7%"
              }}>
                {/* {this.state.isLoading && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><BlockLoading color="#263B95" /></div>} */}
                <Bar options={options} data={data} />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-sm-6 col-rows-3">
            <div className="row">
              <div className="card overflow-hidden radius-10">
                <div className="card-body">
                  <div className=" align-items-stretch justify-content-between overflow-hidden pb-1">
                    <Pie data={pieData} />
                  </div>
                </div>
              </div>
            </div>

          </div> */}
          <div className="col-sm-3">
            <div className="row h-100">

              <div className="card overflow-hidden radius-10">
                <div className="card-body h-100 align-items-center flex justify-content-between">
                  <div className="align-items-center flex justify-content-between mt-3">
                    <div className="">
                      <Pie data={pieData} style={{
                        alignSelf: "center"
                      }} />
                    </div>
                    <h5 className="text-center mt-3">Dealer Ratio</h5>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Dealers on Platform</p>
                    <h4 className="">0</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Logical Transactions</p>
                    <h4 className="">0</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Topupcliq App Users</p>
                    <h4 className="">0</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>TopupCliq Transactions</p>
                    <h4 className="">0</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default Main;
