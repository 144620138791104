import React from "react";

import { Route, Switch } from "react-router-dom";
import CreateMerchant from "./components/createMerchant";
import Transaction from "./components/transaction";
import Users from "./components/users";
import Withdrawals from "./components/withdrawals";
import Invoices from "./components/invoices";

class Vas extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: "someValue",
        };
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route
                        exact
                        path="/bills/transactions"
                        key={25}
                        render={(routerProps) => <Transaction  {...routerProps}></Transaction>}
                    />
                    <Route
                        exact
                        path="/bills/users"
                        key={24}
                        render={(routerProps) => <Users  {...routerProps}></Users>}
                    />
                    <Route
                        exact
                        path="/bills/invoices"
                        key={26}
                        render={(routerProps) => <Invoices {...routerProps}></Invoices>}
                    />
                </Switch>
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            someKey: "otherValue",
        });
    }
}

export default Vas;
