import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "./webbase";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      someKey: "someValue",
      token: cookie.load("token"),
    };

    this.checkCookies = this.checkCookies.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.saveCookies = this.saveCookies.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleSignOut = () => {
    cookie.remove("userAdmin");
    cookie.remove("token");
    cookie.remove("userRole");

    // console.log("signing out");
    clearInterval(this.state.interval);
    this.props.history.push("/signIn");
  };

  checkCookies() {
    const user = cookie.load("userAdmin");
    const token = cookie.load("token");


    if (!user || !token) {
      clearInterval(this.state.interval);
      this.props.history.push("/signIn");
      this.props.navigate(0);
      return;
    }
  }

  saveCookies = (token) => {
    cookie.save("token", token, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("user", this.state.user, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("dealerId", this.state.dealerId, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("userName", this.state.userName, {
      path: "/",
      expires: new Date(Date.now() + 60 * 22 * 1000),
    });
    cookie.save("userRole", this.state.userRole, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("sidebar", this.state.sidebar, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("description", this.state.description, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
  };

  refreshToken() {
    //call refresh token post api
    console.log(this.state.token);
    axios
      .get(
        WEB_BASE_URL + "/admin/refresh-token",
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "2000") {
          //  console.log(res);
          this.setState({
            token: res.data.data.Token,
          });

          this.saveCookies(res.data.data.Token);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          this.props.navigate(0);
        }
        console.log(err);
      });
  }

  componentDidMount() {
    //GET SIDEBAR FROM cookie
    if (cookie.load("sidebar") === undefined) {

      let IntervalId = setInterval(this.checkCookies, 10000);
      this.refreshToken();
      this.setState({
        sidebar: cookie.load("sidebar"),
        interval: IntervalId,
      });
    } else {
      console.log(cookie.load("sidebar"));
    }
  }

  render() {
    return (
      <aside className="sidebar-wrapper bg-white" data-simplebar="true">
        <div className="sidebar-header bg-white">
          <div>
            <img
              src="assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <img
              src="assets/images/llogo2.png"
              className="logo-text"
              style={{ maxWidth: "150px", padding: "10px" }}
              alt="logo text"
            />
          </div>

          <div className="toggle-icon ms-auto">
            {" "}
            <i className="bi bi-list"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu">
          <li>
            <a href="/">
              <div className="parent-icon">
                <i className="bi bi-house-fill"></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </a>
          </li>
          <li>
            <a
              href="/transactions"
              className=""
            >
              <div className="parent-icon">
                <i className="bi bi-bar-chart-fill"></i>
              </div>
              <div className="menu-title">Reports</div>
            </a>
            <ul className="submenu"></ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-gear-wide-connected"></i>
              </div>
              <div className="menu-title">Logical</div>
            </a>
            <ul className="submenu">
              <li>
                <a href="/logical/upload-history">
                  <i className="bi bi-circle"></i>
                  Upload History
                </a>
              </li>
              <li>
                <a href="/logical/purchase-history">
                  <i className="bi bi-circle"></i>
                  Purchase History
                </a>
              </li>

            </ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-briefcase-fill"></i>
              </div>
              <div className="menu-title">Enterprise</div>
            </a>
            <ul className="submenu">
              <li>
                <a href="/enterprise/transactions">
                  <i className="bi bi-circle"></i>
                  Transactions
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-person-fill"></i>
              </div>
              <div className="menu-title">Manage Dealers</div>
            </a>
            <ul className="submenu"><li>
              <a href="/logical/invite-dealer">
                <i className="bi bi-circle"></i>
                Invite Dealer
              </a>
            </li>

              <li>
                <a href="/logical/view-dealers">
                  <i className="bi bi-circle"></i>
                  View Dealers
                </a>
              </li>
              <li>
                <a href="/enterprise/switch-ratio">
                  <i className="bi bi-circle"></i>
                  Switch and Ratio dealer
                </a>
              </li>
              <li>
                <a href="/enterprise/dealercommissons">
                  <i className="bi bi-circle"></i>
                  Dealer commissions
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-person-fill"></i>
              </div>
              <div className="menu-title">Manage Merchants</div>
            </a>
            <ul className="submenu"><li>
              <a href="/logical/invite-merchant">
                <i className="bi bi-circle"></i>
                Invite Merchant
              </a>
            </li>
              <li>
                <a href="/logical/view-merchant">
                  <i className="bi bi-circle"></i>
                  View Merchants
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-phone-vibrate-fill"></i>
              </div>
              <div className="menu-title">VAS - bills</div>
            </a>
            <ul className="submenu">
              <li>
                <a href="/bills/users">
                  <i className="bi bi-circle"></i>
                  List of Users
                </a>
              </li>
              <li>
                <a href="/bills/transactions">
                  <i className="bi bi-circle"></i>
                  Transactions
                </a>
              </li>
              <li>
                <a href="/bills/invoices">
                  <i className="bi bi-circle"></i>
                  Funding Requests
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-app-indicator"></i>
              </div>
              <div className="menu-title">Mobile App and Cliq</div>
            </a>
            <ul className="submenu">
              <li>
                <a href="/mobile/whitelabel-requests">
                  <i className="bi bi-circle"></i>
                  Whitelabel Requests
                </a>
              </li>
              <li>
                <a href="/mobile/users">
                  <i className="bi bi-circle"></i>
                  List of Users
                </a>
              </li>
              <li>
                <a href="/mobile/transactions">
                  <i className="bi bi-circle"></i>
                  Transactions
                </a>
              </li>
              <li>
                <a href="/mobile/withdrawals">
                  <i className="bi bi-circle"></i>
                  Withdrawal Requests
                </a>
              </li>
              <li>
                <a href="/mobile/create-merchant">
                  <i className="bi bi-circle"></i>
                  Create Merchant
                </a>
              </li>
              <li>
                <a href="/mobile/daily-limit">
                  <i className="bi bi-circle"></i>
                  Daily Limit
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-credit-card-fill"></i>
              </div>
              <div className="menu-title">USSD</div>
            </a>
            <ul className="submenu">
              <li>
                <a href="/ussd/users">
                  <i className="bi bi-circle"></i>
                  List of Users
                </a>
              </li>
              <li>
                <a href="/ussd/transactions">
                  <i className="bi bi-circle"></i>
                  Transactions
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="has-arrow"
            >
              <div className="parent-icon">
                <i className="bi bi-person-fill"></i>
              </div>
              <div className="menu-title">Manage Users</div>
            </a>
            <ul className="submenu">
              <li>
                <a href="/invite-users">
                  <i className="bi bi-circle"></i>
                  Invite Admin
                </a>
              </li>
              <li>
                <a href="/view-users">
                  <i className="bi bi-circle"></i>
                  View Admin
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a
              href="void(0)"
              onClick={(e) => {
                e.preventDefault();
              }}
              className=""
            >
              <div className="parent-icon">
                <i className="bi bi-gear-fill"></i>
              </div>
              <div className="menu-title">API Documentation</div>
            </a>
            <ul className="submenu"></ul>
          </li>
          <li>
            <a
              href="/logout"
              onClick={(e) => {
                e.preventDefault();
                this.handleSignOut();
              }}
            >
              <div className="parent-icon">
                <i className="bi bi-lock-fill"></i>
              </div>
              <div className="menu-title">Logout</div>
            </a>
          </li>
        </ul>

        <div class="breadcrumb-item active px-4 mt-4 pt-4 mb-4">
          Copyright © 2022 TopUpBox. All rights reserved.
        </div>
      </aside>
    );
  }

}

export default SideBar;
