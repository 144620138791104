import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WEB_BASE_URL } from "./webbase";
import cookie from "react-cookies";

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: this.props.history,
      otp: "",
      secret: props.location.state.secret ? props.location.state.secret : "",
      authCode: "",
      token: props.location.state.token,
      if2FA: props.location.state.if2FA,
      user: props.location.state.user ? props.location.state.user : null,
      dealerId: props.location.state.dealerId
        ? props.location.state.dealerId
        : null,
      userRole: props.location.state.userRole
        ? props.location.state.userRole
        : null,
      sideBar: props.location.state.sideBar
        ? props.location.state.sideBar
        : null,
      comingFrom: props.location.state.comingFrom,
      userName: props.location.state.userName
        ? props.location.state.userName
        : null,
      description: props.location.state.description
        ? props.location.state.description
        : null,
    };

    //bind functionsToOverrideByDerivedClasses
    this.onSubmit = this.onSubmit.bind(this);
  }

  //on TransactionPinChange
  on2FAChange = (e) => {
    this.setState({
      authCode: e.target.value,
    });
  };

  saveCookies = (token, user) => {
    cookie.save("tokenAdmin", token, {
      path: "/",
      expires: new Date(Date.now() + 60 * 27 * 1000),
    });
    cookie.save("token", token, {
      path: "/",
      expires: new Date(Date.now() + 60 * 27 * 1000),
    });
    cookie.save("userAdmin", user, {
      path: "/",
      expires: new Date(Date.now() + 60 * 27 * 1000),
    });
    this.state.history.push("/");

    return;
  };

  onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    //axios post to sign in
    axios
      .post(
        WEB_BASE_URL + "/admin/validateTotp",
        {
          totp: this.state.authCode,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 2000) {
          toast.update(id, {
            render: "Successfully Verified",
            type: "success",
            isLoading: false,
          });
          if (
            this.state.comingFrom === "verifyEmail" ||
            this.state.comingFrom === "complete-Signup"
          ) {
            this.props.history.push("/successPage", {
              secret: this.state.secret,
              token: this.state.token,
              if2FA: false,
              comingFrom: "verifyEmail",
            });
          } else {
            this.saveCookies(this.state.token, this.state.user);
          }
        } else {
          this.saveCookies(this.state.token, this.state.user);
          console.log({
            token: this.state.token,
            totp: this.state.authCode,
          });
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log({
          token: this.state.token,
          totp: this.state.authCode,
        });
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      });
  };

  onResetPin = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");
    console.log(this.state.token)
    //axios post to sign in
    axios
      .post(WEB_BASE_URL + "/admin/forgot-pin", {}, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        let iflog = res.data.status == 2000 ? true : false;

        if (iflog) {
          toast.update(id, {
            render: "OTP sent to your email",
            type: "success",
            isLoading: false,
          });
          console.log(res.data)

          this.props.history.push("/resetPin", {
            token: this.state.token,
            requestID: res.data.data.requestId,
            userEmailAddress: this.state.formEmail,
          });
        } else {
          console.log(res);
          toast.update(id, {
            render: "Invalid Credentials",
            type: "error",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
          }, 2000);
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };

  render() {
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main className="authentication-content">
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card shadow rounded-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title">
                        Topupbox Admin - Two Factor Authentication
                      </h5>
                      <p>
                        <div></div>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
                          Click here to download Google Authenticator
                        </a>
                      </p>
                      <p>Account Name: {this.state.userName}</p>
                      <form
                        className="form-body"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.onSubmit(event);
                        }}
                      >
                        <div className="row g-3">
                          <div className="col-12">
                            {this.state.if2FA === true ? (
                              <></>
                            ) : (
                              <>
                                <div>
                                  Scan Your QR with Google Authenticator App
                                </div>
                                <div
                                  style={{
                                    background: "white",
                                    padding: "10px",
                                  }}
                                >
                                  <img
                                    src={
                                      "data:image/png;base64, " +
                                      this.state.secret
                                    }
                                    alt="Red dot"
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="col-12 ">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id=""
                                placeholder="Enter Authenticator Code"
                                onChange={this.on2FAChange}
                                value={this.state.authCode}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-6"></div>
                          <div class="col-6 text-end">
                            <a
                              href="/reset-pin"
                              onClick={(e) => {
                                e.preventDefault();
                                this.onResetPin(e);
                              }}
                            >
                              Reset Two Authenticator?
                            </a>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button
                                type="submit"
                                className="btn btn-primary radius-30"
                              >
                                Authenticate
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  componentDidMount() { }
}

export default AuthPage;
