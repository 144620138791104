import React from "react";

import { Route, Switch } from "react-router-dom";
import FailedTransactions from "./components/failedTransactions";
import Switche from "./components/switchRatio";
import Transactions from "./components/transactions";
import DealerCommissions from "./components/dealerCommissions";

class Enterprise extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: "someValue",
        };
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route
                        exact
                        path="/enterprise/transactions"
                        key={40}
                        render={(routerProps) => <Transactions></Transactions>}
                    />

                    <Route
                        exact
                        path="/enterprise/switch-ratio"
                        key={42}
                        render={(routerProps) => <Switche></Switche>}
                    />
                    <Route
                        exact
                        path="/enterprise/dealercommissons"
                        key={42}
                        render={(routerProps) => <DealerCommissions></DealerCommissions>}
                    />
                    {/* <Route
                        exact
                        path="/enterprise/switch-ratio"
                        key={43}
                        render={(routerProps) => <Ratio></Ratio>}
                    /> */}
                </Switch>
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            someKey: "otherValue",
        });
    }
}

export default Enterprise;
